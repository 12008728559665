$body-bg: #333;
$body-color: #fff;

@import 'bootstrap';

body {
    border: 20px solid black;

    &.found {
        border: 20px solid #0F0;
    }
}

.clickable {
    cursor: pointer;
}

button {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99999;
}